if (process.env.NODE_ENV === "production") {

    const loadjs = require("loadjs");

    loadjs(["https://www.googletagmanager.com/gtag/js?id=UA-100381388-2"], "google");

    loadjs.ready("google", () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {window.dataLayer.push(arguments)};
        window.gtag("js", new Date());
        window.gtag("config", "UA-100381388-2");
    });

}
