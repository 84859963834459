if (process.env.NODE_ENV === "production") {

    (function (m, e, t, r, i, k, a) {
        m[i] = m[i] || function () {
            (m[i].a = m[i].a || []).push(arguments);
        };
        m[i].l = 1 * new Date();
        k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    window.ym(61548412, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    });

    document.querySelector("body").insertAdjacentHTML(
        "beforeend",
        "<noscript><div><img src=\"https://mc.yandex.ru/watch/61548412\" style=\"position:absolute; left:-9999px;\" alt=\"\"/></div></noscript>"
    );

}
